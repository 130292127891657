var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "custom-class": "agrementForMechat",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        top: "0",
        width: "580px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "closeBox" }, [
        _c("i", {
          staticClass: "icon-x iconfont_Me iconStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.closeDialog.apply(null, arguments)
            }
          }
        })
      ]),
      _c("div", { staticClass: "dialogHeaderContainer" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.replaceMechati18n(_vm.$t("mechatAgreement").title)) +
            "\n  "
        )
      ]),
      _c(
        "div",
        {
          staticClass: "dialogContainer",
          style: { "padding-bottom": _vm.hiddenButton ? "50px" : "20px" }
        },
        [
          _c(
            "div",
            { staticClass: "contentBox" },
            _vm._l(_vm.texts, function(item, index) {
              return _vm.texts
                ? _c("div", { key: index, staticClass: "text" }, [
                    item.header
                      ? _c("p", { staticClass: "innerTitle" }, [
                          _vm._v(
                            "\n          " + _vm._s(item.content) + "\n        "
                          )
                        ])
                      : _c("p", [
                          _vm._v("\n          " + _vm._s(item) + "\n        ")
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ]
      ),
      !_vm.hiddenButton
        ? _c("div", { staticClass: "dialogFooter" }, [
            _c(
              "div",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticClass: "commonButton active",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.nextStep.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me icon-tubiaozhizuomoban iconStyle"
                })
              ]
            ),
            _c("p", { staticClass: "tips" }, [
              _vm._v("(" + _vm._s(_vm.$t("agreeRobotAgreement")) + ")")
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }