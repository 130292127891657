<template>
  <el-dialog
    center
    custom-class="agrementForMechat"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :top="'0'"
    :width="'580px'"
  >
    <div class="closeBox">
      <i class="icon-x iconfont_Me iconStyle" @click.stop="closeDialog"></i>
    </div>
    <div class="dialogHeaderContainer">
      {{ replaceMechati18n($t("mechatAgreement").title) }}
    </div>
    <div
      class="dialogContainer"
      :style="{ 'padding-bottom': hiddenButton ? '50px' : '20px' }"
    >
      <div class="contentBox">
        <div
          v-if="texts"
          class="text"
          v-for="(item, index) in texts"
          :key="index"
        >
          <p v-if="item.header" class="innerTitle">
            {{ item.content }}
          </p>
          <p v-else>
            {{ item }}
          </p>
        </div>
      </div>
    </div>
    <div class="dialogFooter" v-if="!hiddenButton">
      <div class="commonButton active" v-waves @click.stop="nextStep">
        <span class="iconfont_Me icon-tubiaozhizuomoban iconStyle"></span>
      </div>
      <p class="tips">({{ $t("agreeRobotAgreement") }})</p>
    </div>
  </el-dialog>
</template>
<script>
import Bus from "@/utils/bus.js";
export default {
  name: "AgrementForMechat",
  computed: {
    dialogVisible() {
      return this.$store.state.globalShare.agrementForMechat;
    },
    robotInfo() {
      return this.$store.state.robot.robotInfo;
    },
    hiddenButton() {
      return this.robotInfo && this.robotInfo.processStatus == 2;
    },
    mechat() {
      let mechat = {
        title: "",
        text: [],
      };
      const data = this.$t("mechatAgreement");
      for (let item in data) {
        if (item == "title") {
          mechat.title = data[item];
        } else if (item.indexOf("text") != -1) {
          mechat.text.push(this.replaceMechati18n(data[item]));
        } else if (item.indexOf("header") != -1) {
          mechat.text.push({
            header: true,
            content: data[item],
          });
        }
      }
      return mechat;
    },
    texts() {
      return this.mechat.text;
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit("setAgrementForMechat", false);
    },
    nextStep() {
      Bus.$emit("agreeToTheRobotAgreement");
    },
  },
};
</script>
<style lang="stylus" scoped>
.agrementForMechat
  .closeBox
    width: 100%;
    height: 24px;
    text-align: right;
    position: absolute;
    top: 24px;
    .iconStyle
      font-size: 24px;
      color: #000000;
      margin-right: 13px;
      cursor pointer;
  .dialogHeaderContainer
    width: 100%;
    color: #000000;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding:24px 0 30px 0;
    box-sizing: content-box;
  .dialogContainer
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    .contentBox
      width: 100%;
      overflow: hidden;
      color: #000000;
      font-size: 16px;
      line-height: 24px;
      word-break: normal;
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 45px;
      &::-webkit-scrollbar
        width: 4px;
      &::-webkit-scrollbar-thumb
        border-radius: 2px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
        background: rgba(100, 100, 100, 0.1);
      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      p
        padding 0;
        margin: 10px 0;
      .title
        font-size: 16px;
        font-weight: 700;
      .text
        font-size: 12px;
        .innerTitle
          font-size: 14px;
          font-weight: 600;
  .dialogFooter
    width: 240px;
    margin: auto;
    overflow: hidden;
    padding-bottom: 26px;
    .tips
      width: 100%;
      height: 24px;
      text-align: center;
      font-size: 12px;
      color #000000;
      line-height: 24px;
      user-select: none;
    .commonButton
      width: 100%;
      height: 34px;
      border-radius: 5px;
      margin-top: 10px;
      text-align: center;
      line-height: 34px;
      font-size: 15px;
      cursor pointer;
      font-weight: 700;
      user-select none;
      .iconStyle
        font-size: 20px;
        color:#FFFFFF
    .active
      color: #FFFFFF;
      background: #33CC66;
</style>
<style lang="stylus">
.agrementForMechat
  position: relative;
  border-radius: 15px;
  overflow hidden;
  transition: width 0.3s, height 0.3s;
  background: linear-gradient(180deg,#fff3df, #f5f5f5 100%, #f5f5f5 100%)
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding 0;
</style>
